import { Injectable } from '@angular/core';
import { Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SidenavService {
    public toggleSidenavObs = new BehaviorSubject<boolean>(false);
    public toggleSidenavObs$: Observable<boolean> = this.toggleSidenavObs.asObservable();

    public breakPointsThreshold = [Breakpoints.XSmall, Breakpoints.Small];

    constructor() {}

    public toggle() {
        this.toggleSidenavObs.next(!this.toggleSidenavObs.value);
    }
}
