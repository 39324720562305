<mat-drawer-container class="example-container" hasBackdrop="true">
    <mat-drawer #drawer mode="over" *ngIf="!displaySidenavClass">
        <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
        <div class="sidenav-content">
            <app-sidenav *ngIf="displaySidenavClass"></app-sidenav>
            <div class="container-fluid main-container content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
