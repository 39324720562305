import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, TextfieldModule } from '@ed---interne/ng-uui-components';
import { EdModalConfirmComponent } from './ed-modal-confirm.component';

@NgModule({
    declarations: [EdModalConfirmComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule, TextfieldModule],
    exports: [EdModalConfirmComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EdModalConfirmModule {}
