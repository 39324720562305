('use strict');

import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ToastService } from '../../../../core/services/toast.service';

@Component({
    selector: 'ed-input-file',
    templateUrl: './ed-input-file.component.html',
    styleUrls: ['./ed-input-file.component.scss'],
})
export class EdInputFileComponent {
    @Input() id = '';
    @Input() placeholder = '';
    @Input() isUploading: boolean = false;
    @Input() allowedFormat: string[] | null = null;
    @Output() onSubmit = new EventEmitter<File>();

    constructor(private toastService: ToastService) {}

    async uploadByEvent(event: any) {
        const files: FileList = event.target.files;
        const file: File | null = files.item(0);
        event.stopPropagation();
        event.preventDefault();
        this.upload(file);
    }

    async upload(file: File | null) {
        if (
            this.allowedFormat &&
            !this.allowedFormat
                .map((format) => format.toLocaleLowerCase())
                .includes(file?.name.split('.').pop()?.toLocaleLowerCase() || '&ù$')
        ) {
            this.toastService.showErrorToast('Format de fichier non valide');
            return;
        }
        if (file) {
            this.onSubmit.emit(file);
        }
    }
}
