import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { Store } from '@ngxs/store';
import { User } from 'src/app/core/models/user.model';
import { createUser, DeleteUser, UpdateUser } from 'src/app/main/states/adminUsers.state';
import { emailValidator } from 'src/app/shared/validators/email.validator';
import { passwordValidator } from 'src/app/shared/validators/password.validator';

@Component({
    selector: 'user-update',
    templateUrl: './user-update.component.html',
    styleUrls: ['./user-update.component.scss'],
})
export class UserUpdateComponent {
    public buttonColors = ButtonColors;

    public user!: User;
    public password: string | null = null;
    public passwordConfirm: string | null = null;
    public header: any;
    public operation: any;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
        private readonly alertService: AlertService,
    ) {}

    public userFormGroup = this.formBuilder.group({
        email: ['', [emailValidator()]],
        password: ['', [passwordValidator()]],
        firstname: [''],
        lastname: [''],
        passwordConfirm: ['', [passwordValidator()]],
        isAdmin: [false],
    });

    public valid(): void {
        if (
            this.userFormGroup.value.password &&
            this.userFormGroup.value.password === this.userFormGroup.value.passwordConfirm
        ) {
            this.user.password = this.userFormGroup.value.password;
        } else {
            this.alertService.error('Utilisateur', 'Les mots de passe ne correspondent pas');
        }

        if (
            !this.userFormGroup.value.password ||
            this.userFormGroup.value.password === this.userFormGroup.value.passwordConfirm
        ) {
            const userToUpdate: User = {
                id: this.user.id,
                mail: this.userFormGroup.value.email as string,
                firstname: this.userFormGroup.value.firstname as string,
                lastname: this.userFormGroup.value.lastname as string,
                password: this.userFormGroup.value.password as unknown as string,
                isAdmin: this.userFormGroup.value.isAdmin as boolean,
                isActive: false,
                createdAt: this.user.createdAt,
                updatedAt: this.user.updatedAt,
                lastConnexionAt: this.user.lastConnexionAt,
            };

            if (this.operation == 'update') {
                this.store.dispatch(new UpdateUser({ user: userToUpdate })).subscribe({
                    next: () => {
                        this.alertService.valid('Utilisateurs', "L'utilisateur a bien été modifié");
                    },
                    error: (err) => {
                        this.alertService.error(
                            'Enregistrement',
                            "une erreur est survenue : Impossible de mettre à jour l'utilisateur",
                        );
                    },
                });

                this.close();
            } else {
                //create
                this.store.dispatch(new createUser({ user: userToUpdate })).subscribe({
                    next: () => {
                        this.alertService.valid('Utilisateurs', 'Utilisateur créé !');
                    },
                    error: (err) => {
                        this.alertService.error(
                            'Création',
                            "une erreur est survenue : Impossible de créer l'utilisateur",
                        );
                    },
                });
                this.close();
            }
        }
    }

    isMail(input: string): boolean {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return expression.test(input);
    }

    public set params(params: { operation: any; header: any; user: User }) {
        this.user = params.user;
        this.header = params.header;
        this.operation = params.operation;

        this.userFormGroup.patchValue({
            email: this.user.mail,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            isAdmin: this.user.isAdmin,
            password: '',
            passwordConfirm: '',
        });
    }

    public close(): void {
        this.layerService.close();
    }

    public canCreateEdit(): boolean {
        return this.userFormGroup.invalid || !this.userFormGroup.dirty;
    }
}
