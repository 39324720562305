import { Component } from '@angular/core';
import { ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { Store } from '@ngxs/store';
import { User } from '../../../../core/models/user.model';
import { UpdateUser } from '../../../../main/states/adminUsers.state';

@Component({
    selector: 'EdModalConfirm',
    templateUrl: './ed-modal-confirm.component.html',
    styleUrls: ['./ed-modal-confirm.component.scss'],
})
export class EdModalConfirmComponent {
    public user!: User;
    public header: any;

    public contentText: any;
    public buttonUndoText: any;
    public buttonConfirmText: any;
    public buttonConfirmVariant: any;

    public buttonColors = ButtonColors;

    constructor(
        private readonly layerService: LayerService,
        private readonly store: Store,
    ) {}

    confirm() {
        this.store.dispatch(new UpdateUser({ user: this.user }));
        this.close();
    }
    undo() {
        this.close();
    }

    public set params(params: {
        user: User;
        header: string;
        buttonConfirmText: string;
        contentText: string;
        buttonUndoText: string;
    }) {
        this.user = params.user;
        this.header = params.header;
        this.contentText = params.contentText;
        this.buttonConfirmText = params.buttonConfirmText;
        this.buttonUndoText = params.buttonUndoText;
    }

    public close(): void {
        this.layerService.close();
    }
}
