import { Inject, Injectable } from '@angular/core';
import { RestService } from '../../core/services/rest.service';
import { Document } from '../models/document.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    constructor(@Inject(RestService) private restService: RestService) {}

    public async getAll(folderName: string = 'myFolder'): Promise<Document[]> {
        const path = `/document/folder/${folderName}`;
        const docs = await this.restService.get(path, {});
        return docs;
    }

    public get(documentId: string): Observable<Object> {
        const path = `/document/${documentId}`;
        return this.restService.download(path, {});
    }

    public async delete(documentId: string): Promise<any> {
        const path = `/document/${documentId}`;
        return await this.restService.delete(path, {});
    }

    public async upload(folderName: string, file: File): Promise<Document> {
        const path = `/document/${folderName}`;
        const formData: FormData = new FormData();
        formData.append('file', file);

        return await this.restService.post(path, {}, formData);
    }
}
