import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { User } from '../../core/models/user.model';
import { AdminUsersService } from '../services/adminUsers.service';
import { Pagination, PaginationInfo, PropertyCount } from '../../../app/shared/all.types';
import { Observable, tap } from 'rxjs';
import { UsersPageSize } from '../../../app/shared/all.constants';
import { SetUsersPagination } from './pagination.state';

/** This file is divided in 3 parts:
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 *
 */

// Store definition
export interface AdminUsersStateModel {
    users: User[] | null;
    isLoading: boolean;
    hasMoreUsers: boolean;
}

//Constants

export class GetUsers {
    static readonly type = 'ADMIN/USERS/ALL';
}
export class DeleteUser {
    static readonly type = 'ADMIN/USERS/DELETE';
    constructor(public payload: { userId: string }) {}
}
export class UpdateUser {
    static readonly type = 'ADMIN/USERS/UPDATE';
    constructor(public payload: { user: User }) {}
}

export class createUser {
    static readonly type = 'ADMIN/USERS/CREATE';
    constructor(public payload: { user: User }) {}
}

export class GetPaginatedUsers {
    static readonly type = 'ADMIN/GET_USERS_BATCH';

    constructor(
        public payload?: Pagination,
        public reset?: boolean,
    ) {}
}

export class GetUserById {
    static readonly type = 'ADMIN/USERS/ID';
}

export class GetPropertyCountsSuccess {
    static readonly type = 'ADMIN/GET_PROPERTY_COUNTS_SUCCESS';

    constructor(public propertyCounts: PropertyCount[]) {}
}

export class GetProperty {
    static readonly type = 'PROPERTY/GET_PROPERTY';

    constructor(public id: number) {}
}

//Actions

@State<AdminUsersStateModel>({
    name: 'AdminUsers',
    defaults: {
        users: [],
        isLoading: false,
        hasMoreUsers: false,
    },
})
@Injectable()
export class AdminUsersState {
    @Selector()
    public static users(state: AdminUsersStateModel): User[] | null {
        return state.users;
    }

    @Selector()
    public static hasMoreUsers(state: AdminUsersStateModel): boolean {
        return state.hasMoreUsers;
    }

    constructor(
        private adminUsersService: AdminUsersService,
        private readonly store: Store,
    ) {}

    @Action(GetUsers)
    public async getUsers(ctx: StateContext<AdminUsersStateModel>): Promise<void> {
        ctx.patchState({ isLoading: true });

        const users = await this.adminUsersService.getUsers();
        ctx.patchState({ users: users, isLoading: false });
    }

    @Action(GetUserById)
    public async getUserById(ctx: StateContext<AdminUsersStateModel>): Promise<void> {
        ctx.patchState({ isLoading: true });

        const users = await this.adminUsersService.getUsers();
        ctx.patchState({ users: users, isLoading: false });
    }

    @Action(UpdateUser)
    public async updateUser(
        ctx: StateContext<AdminUsersStateModel>,
        action: UpdateUser,
    ): Promise<void> {
        try {
            const msg = await this.adminUsersService.updateUser(action.payload.user);
            ctx.dispatch(GetUsers);
        } catch (err: any) {
            console.log(err);
        }
    }

    @Action(createUser)
    public async createUser(
        ctx: StateContext<AdminUsersStateModel>,
        action: createUser,
    ): Promise<void> {
        try {
            const msg = await this.adminUsersService.createUser(action.payload.user);
            ctx.dispatch(GetUsers);
        } catch (err: any) {
            console.log(err);
        }
    }

    @Action(DeleteUser)
    public async deleteUser(
        ctx: StateContext<AdminUsersStateModel>,
        action: DeleteUser,
    ): Promise<void> {
        try {
            const msg = await this.adminUsersService.deleteUser(action.payload.userId);
            ctx.dispatch(GetUsers);
        } catch (err: any) {
            console.log(err);
        }
    }

    @Action(GetPaginatedUsers)
    public getPaginatedUsers(
        ctx: StateContext<AdminUsersStateModel>,
        action: GetPaginatedUsers,
    ): Observable<User[]> {
        if (!action.payload || action.reset) {
            this.store.dispatch(new SetUsersPagination(action.payload, action.reset));
            ctx.patchState({ users: null });
        }

        this.store.dispatch(new SetUsersPagination(action.payload));
        const currentUsers = ctx.getState().users || [];

        return this.adminUsersService.getPaginatedUsers(action.payload).pipe(
            tap((result: User[]) => {
                ctx.patchState({
                    users: currentUsers.concat(result || []),
                    hasMoreUsers: result.length === UsersPageSize,
                });
            }),
        );
    }
}
