import { Component, OnInit } from '@angular/core';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { User } from '../../../core/models/user.model';
import { AuthState } from '../../../core/states/auth.state';
import { SidenavService } from 'src/app/main/services/sidenav.service';

interface MenuItem {
    label: string;
    hidden?: boolean;
    routerLink: any[];
    icon: string;
    isDivider?: boolean;
    routerLinkActiveOptions?: { exact: boolean } | IsActiveMatchOptions;
    panelOpenState?: boolean;
    subItems: MenuItem[];
}

@UntilDestroy()
@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
    public loggedUser$!: Observable<User | null>;

    public topMenuItems!: MenuItem[];
    public bottomMenuItems!: MenuItem[];

    constructor(
        private readonly store: Store,
        private router: Router,
        private sidenavService: SidenavService,
    ) {}

    ngOnInit(): void {
        this.loggedUser$ = this.store.select(AuthState.loggedUser);

        this.loggedUser$.pipe(filter(Boolean), untilDestroyed(this)).subscribe((loggedUser) => {
            this.topMenuItems = [
                {
                    label: 'Accueil',
                    icon: 'icon-mitoyen',
                    routerLink: ['/main'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    subItems: [],
                },
                {
                    label: 'Statistiques',
                    icon: 'icon-bar-chart-10',
                    routerLink: ['/main/stats'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    subItems: [],
                },
                {
                    label: 'Contact',
                    icon: 'icon-mail-01',
                    routerLink: ['/main/contact'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    subItems: [],
                },
                {
                    label: 'Fichiers',
                    icon: 'icon-file-doc',
                    routerLink: ['/main/files'],
                    routerLinkActiveOptions: { exact: true },
                    hidden: false,
                    subItems: [],
                },
            ];

            this.bottomMenuItems = [
                {
                    label: 'Administration',
                    icon: 'icon-tool-01',
                    routerLink: ['/admin/users'],
                    routerLinkActiveOptions: { exact: true },
                    subItems: [
                        {
                            label: 'Utilisateurs',
                            icon: '',
                            routerLink: ['/admin/users'],
                            routerLinkActiveOptions: { exact: true },
                            panelOpenState: false,
                            subItems: [],
                        },
                    ],
                },
            ];
        });

        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._updatePanelState(event.urlAfterRedirects);
            }
        });

        const currentUrl = this.router.url;
        this._updatePanelState(currentUrl);
    }

    private _updatePanelState(currentUrl: string): void {
        this.topMenuItems.forEach((menuItem) => {
            const subItemsRouterLinks = menuItem.subItems.map((item) => item.routerLink[0]);
            menuItem.panelOpenState = subItemsRouterLinks.includes(currentUrl);
        });

        this.bottomMenuItems.forEach((menuItem) => {
            const subItemsRouterLinks = menuItem.subItems.map((item) => item.routerLink[0]);
            menuItem.panelOpenState = subItemsRouterLinks.includes(currentUrl);
        });
    }

    public handleClickOnItem() {
        this.sidenavService.toggle();
    }
}
