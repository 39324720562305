import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { DropdownMenuModule } from '@ed---interne/ng-uui-components';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { DocumentLabelModule } from '../document-label/document-label.module';
import { EdTableComponent } from './ed-table.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    declarations: [EdTableComponent],
    exports: [EdTableComponent],
    imports: [
        CommonModule,
        DropdownMenuModule,
        MatSortModule,
        MatTableModule,
        DocumentLabelModule,
        NgOptimizedImage,
        NgxTippyModule,
        MatMenuModule,
    ],
})
export class EdTableModule {}
