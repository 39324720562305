<div class="page page-padding">
    <div class="page-header">
        <h1 class="page-title">Statistiques</h1>
        <div class="page-description">Page de statistiques</div>
    </div>

    <div class="page-content">
        <p>stats works!</p>
    </div>
</div>
