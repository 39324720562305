<div class="page">
    <div class="page-padding"> 
        <div class="page-header">
            <div class="page-container">
                <div class="page-title">Gestion des utilisateurs</div>
                <div class="page-description">Créez, modifiez et supprimez des utilisateurs</div>
            </div>
        </div>
        <div class="search-and-button">      
                <ed-textfield 
                    [ngModel]="paginationInfo.search"
                    (ngModelChange)="applyFilter($event)"
                    placeholder="Chercher un prénom, nom, mail"
                    [icon]="'icon-search-lg'"
                ></ed-textfield>
                <ed-button *ngIf="!isLoadingUsers" class="btnSecondary" (click)="openModalCreateUser()">
                    Ajouter un utilisateur
                </ed-button>
        </div>
    </div>    

    <div class="page-content">
        <ed-table
            (onDropdownMenuClick)="onDropdownMenuClick($event.object, $event.event)"
            (onRowClick)="goToDetail($event)"
            [dataSource]="dataSource"
            [displayedColumns]="displayedColumns"
            [areActionsInElements]="true"
            EdInfiniteScroll
            [listenToScroll]="hasMoreUsers$ | async"
            [sortInfo]="paginationInfo.orderBy"
            (nearEnd)="displayMoreUsers()"
            (onSortChanged)="onSortChange($event)">
        </ed-table>
        <!-- when more users to display -->
        <ed-skeleton-list *ngIf="isLoadingUsers"></ed-skeleton-list>
        @if ((users$ | async) === null) {
            <ed-skeleton-list></ed-skeleton-list>
        }
    </div>
</div>
