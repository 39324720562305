<div class="main">
  <div class="left">
    <div class="container">
      <router-outlet></router-outlet>
    </div>

    <div class="footer">
      <div>© Energies Demain</div>
      <div class="contact"><i class="icon-mail-01"></i>contact&#64;templateweb.fr</div>
    </div>
  </div>

  <div class="right">
    <div class="presentation-slogan">
      <div class="logo">
        <img src="../../../assets/img/logo_ed.png" alt="logo">
      </div>
      <div class="slogan-text">
        Ensemble, accélérons la transition énergétique des logements
      </div>
    </div>
    <div class="presentation-image">
      <div class="image"></div>
    </div>
  </div>
</div>
