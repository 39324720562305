import { Inject, Injectable } from '@angular/core';
import { RestService } from '../../core/services/rest.service';
import { User } from '../../core/models/user.model';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Pagination, PaginationInfo, PropertyCount } from 'src/app/shared/all.types';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UsersPageSize } from '../../../app/shared/all.constants';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AdminUsersService {
    private _users = false;

    constructor(
        @Inject(RestService) private restService: RestService,
        private store: Store,
        private router: Router,
        private readonly http: HttpClient,
    ) {}

    public get users(): boolean {
        return this._users;
    }

    private _getBaseUrl(user: string): string {
        const baseUrl = `${environment.apiUrl}${user}`;
        return baseUrl;
    }

    public async getUsers(): Promise<User[]> {
        const path = '/user/all';
        const users = await this.restService.get(path, {});
        return users;
    }

    public async getUserById(userId: number): Promise<User> {
        const path = `/user/infos/${userId}`;
        const user = await this.restService.get(path, {});
        return user;
    }

    public async deleteUser(userId: string): Promise<void> {
        const path = `/user/${userId}`;
        await this.restService.delete(path, {});
    }

    public async updateUser(user: User): Promise<void> {
        const path = `/user`;
        await this.restService.put(path, {}, user);
    }

    public async createUser(user: User): Promise<void> {
        const path = `/user`;
        await this.restService.post(path, {}, user);
    }

    public getPaginatedUsers(paginationInfo?: Pagination): Observable<User[]> {
        const pagination = {
            ...paginationInfo,
            perPage: UsersPageSize,
        };
        const path = `/user/get`;
        return this.http.post<User[]>(this._getBaseUrl(path), pagination, {});
    }
}
