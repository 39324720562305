import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EdInputComponent } from './components/forms/ed-input/ed-input.component';
import { FormsModule } from '@angular/forms';
import {
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { EdDatepickerComponent } from './components/forms/ed-datepicker/ed-datepicker.component';
import { EdTextareaComponent } from './components/forms/ed-textarea/ed-textarea.component';
import {
    CustomAdapter,
    CustomDateParserFormatter,
} from './components/forms/ed-datepicker/ngbd-datepicker.adapter';
import { ComponentsModule } from '@ed---interne/ng-uui-components';
import { EdInputFileComponent } from './components/forms/ed-input-file/ed-input-file.component';
import { DropZoneDirective } from './components/forms/ed-input-file/dropzone.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';

import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [
        EdInputComponent,
        EdDatepickerComponent,
        EdTextareaComponent,
        EdInputFileComponent,
        DropZoneDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbDatepickerModule,
        NgbDropdownModule,
        NgbCollapseModule,
        ComponentsModule,
        InfiniteScrollDirective,
        MatSelectModule,
    ],
    exports: [
        EdInputComponent,
        EdDatepickerComponent,
        EdTextareaComponent,
        EdInputFileComponent,
        DropZoneDirective,
        InfiniteScrollDirective,
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    ],
})
export class SharedModule {}
