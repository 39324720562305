<div class="icon-box info mb-3"><i class="icon icon-log-in-04"></i></div>
<h1>Se connecter</h1>

<form (ngSubmit)="login()" [formGroup]="loginFormGroup">
    <div class="form-group">
        <ed-textfield
            id="email"
            [type]="'email'"
            [placeholder]="'Votre email'"
            [displayError]="displayError"
            [formControlName]="'email'"
        ></ed-textfield>
    </div>
    <div class="form-group">
        <ed-textfield
            id="password"
            [type]="'password'"
            [placeholder]="'••••••••'"
            [formControlName]="'password'"
        ></ed-textfield>
    </div>
    <div *ngIf="loginFormGroup.get('email')?.hasError('email')" class="alert-msg">
        Email invalide
    </div>
    <div class="button-group">
        <ed-button [type]="ButtonTypes.Submit"
                [loading]="loading"
                [disabled]="!loginFormGroup.valid">Se connecter</ed-button>
    </div> 
</form>

<a routerLink="/forgot-password" href="/forgot-password">Mot de passe oublié</a>


<span><small><b>Utilisateur admin :</b> admin&#64;ed.fr | ed4you;</small></span>
<span><small><b>Utilisateur normal :</b> user&#64;ed.fr | ed4you;</small></span>
<span><small><b>Utilisateur désactivé :</b> disabled&#64;ed.fr | ed4you;</small></span>


