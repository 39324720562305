export enum DocumentCategoryIndex {
    DiagnosticsAndPlans = 1,
    Works = 2,
    EquipmentAndMaintenance = 3,
    UrbanPlanning = 4,
    Notary = 5,
    Insurance = 6,
    Rental = 7,
    CoOwnership = 8,
    Finance = 9,
    Other = 10,
}

export const DOCUMENT_CATEGORY_MAP = new Map<DocumentCategoryIndex, string>([
    [DocumentCategoryIndex.DiagnosticsAndPlans, 'Diagnostics et plans'],
    [DocumentCategoryIndex.Works, 'Travaux'],
    [DocumentCategoryIndex.EquipmentAndMaintenance, 'Equipements et entretien'],
    [DocumentCategoryIndex.UrbanPlanning, 'Urbanisme'],
    [DocumentCategoryIndex.Notary, 'Notaire'],
    [DocumentCategoryIndex.Insurance, 'Assurance'],
    [DocumentCategoryIndex.Rental, 'Location'],
    [DocumentCategoryIndex.CoOwnership, 'Copropriété'],
    [DocumentCategoryIndex.Finance, 'Finances'],
    [DocumentCategoryIndex.Other, 'Autre catégorie'],
]);

export enum Label {
    ShowQuestionUpkeepCertificate = -9,
    ShowQuestionInsuranceCertificate = -8,
    ShowQuestionDiagnosticType = -7,
    ShowQuestionFinancialHelp = -6,
    ShowQuestionSinisterTypology = -5,
    ShowQuestionTechnicalNetwork = -4,
    ShowQuestionWorkLots = -3,
    ShowQuestionEquipmentList = -2,
    ShowQuestionWorkPrograms = -1,

    // TYPES
    Estimate = 1,
    Invoice = 2,
    Notice = 3,
    Description = 4,
    Photo = 5,
    GeneralPlan = 6,
    NetworkPlan = 7,
    BuildingPermit = 8,
    Statement = 9,
    DemolitionPermit = 10,
    PlanningPermit = 11,
    InsuranceCertificate = 12,
    Sinister = 13,
    Report = 14,
    ActOfSale = 15,
    CoOwnershipRegulations = 16,
    Lease = 17,
    FixturesState = 18,
    RentReceipt = 19,
    Diagnostic = 20,
    BreakdownAndRepair = 21,
    SalesAgreement = 22,
    MaintenanceCertificate = 23,
    RGECertificate = 24,
    CallForCharges = 25,
    FinancingPlan = 26,
    FinancingHelp = 27,
    Other = 999,

    // WORK LOTS
    FloorTiles = 28,
    Frame = 29,
    Heating = 30,
    Roofing = 31,
    Electricity = 32,
    Insulation = 33,
    Masonry = 34,
    SubjectMastery = 35,
    Carpentry = 36,
    Painting = 37,
    Plastering = 38,
    Plumbing = 39,

    // TECHNICAL NETWORK
    ElectricityNetwork = 40,
    GazNetwork = 41,
    DrinkingWaterNetwork = 42,
    WasteWaterNetwork = 43,
    VentilationNetwork = 44,

    // SINISTER TYPOLOGY
    WaterDamage = 45,
    ClimaticHazards = 46,
    Heist = 47,
    Fire = 48,
    BrokenGlass = 49,
    Flooding = 50,
    OtherClaims = 51,

    // FINANCIAL HELP
    MaPrimeRenov = 52,
    CEE = 53,
    EcoPTZ = 54,
    LocalAids = 55,

    // DIAGNOSTIC TYPE
    DPE = 56,
    EnergeticDiagnostic = 57,
    ElectricityDiagnostic = 58,
    GazDiagnostic = 59,
    RiskDiagnostic = 60,
    SanitationDiagnostic = 61,
    AsbestosDiagnostic = 62,
    LeadDiagnostic = 63,
    ParasitesDiagnostic = 64,

    // INSURANCE CERTIFICATE
    HabitationCertificate = 65,
    LandlordCertificate = 66,
    DamageWorkCertificate = 67,
    UnpaidRentCertificate = 68,
    DecennialCertificate = 69,

    // UPKEEP CERTIFICATE
    ChimneySweepingCertificate = 70,
    MudClearingCertificate = 71,
    PitEmptyingCertificate = 72,
}

export const DOCUMENT_LABEL_MAP = new Map<Label, string>([
    // TYPES
    [Label.Estimate, 'Devis'],
    [Label.Invoice, 'Facture'],
    [Label.Notice, 'Notice'],
    [Label.Description, 'Descriptif'],
    [Label.Photo, 'Photo'],
    [Label.GeneralPlan, 'Plan général'],
    [Label.NetworkPlan, 'Plan de réseau'],
    [Label.BuildingPermit, 'Permis de construire'],
    [Label.Statement, 'Déclaration préalable'],
    [Label.DemolitionPermit, 'Permis de démolir'],
    [Label.PlanningPermit, "Permis d'aménager"],
    [Label.InsuranceCertificate, "Attestation d'assurance"],
    [Label.Sinister, 'Sinistre'],
    [Label.Report, 'Compte rendu'],
    [Label.ActOfSale, 'Acte de vente'],
    [Label.CoOwnershipRegulations, 'Règlement de copropriété'],
    [Label.Lease, 'Bail'],
    [Label.FixturesState, 'Etat des lieux'],
    [Label.RentReceipt, 'Quittance'],
    [Label.Diagnostic, 'Diagnostic'],
    [Label.BreakdownAndRepair, 'Panne & réparation'],
    [Label.SalesAgreement, 'Compromis de vente'],
    [Label.MaintenanceCertificate, "Attestation d'entretien"],
    [Label.RGECertificate, 'Attestation RGE'],
    [Label.CallForCharges, 'Appel de charges'],
    [Label.FinancingPlan, 'Plan de financement'],
    [Label.FinancingHelp, 'Aides financières'],
    [Label.Other, 'Autre type'],

    // WORK LOTS
    [Label.FloorTiles, 'Carrelage'],
    [Label.Frame, 'Charpente'],
    [Label.Heating, 'Chauffage'],
    [Label.Roofing, 'Couverture'],
    [Label.Electricity, 'Electricité'],
    [Label.Insulation, 'Isolation'],
    [Label.Masonry, 'Maçonnerie'],
    [Label.SubjectMastery, "Maitrise d'oeuvre"],
    [Label.Carpentry, 'Menuiserie'],
    [Label.Painting, 'Peinture'],
    [Label.Plastering, 'Platerie'],
    [Label.Plumbing, 'Plomberie'],

    // EQUIPMENT LIST TAGS
    /*  [Label.HeatingSystem, 'Système de chauffage'],
    [Label.HotWater, 'Eau chaude sanitaire'],
    [Label.AirConditioning, 'Climatisation'],
    [Label.Cooking, 'Cuisson'],
    [Label.HomeAppliances, 'Electroménager'],
    [Label.Ventilation, 'Ventilation'],
    [Label.Automation, 'Domotique'],*/

    // Network Plan Tags //
    [Label.ElectricityNetwork, 'Electricité'],
    [Label.GazNetwork, 'Gaz'],
    [Label.DrinkingWaterNetwork, 'Eau potable'],
    [Label.WasteWaterNetwork, 'Eaux usées'],
    [Label.VentilationNetwork, 'Ventilation'],

    // Sinister Tags //
    [Label.WaterDamage, 'Dégats des eaux'],
    [Label.ClimaticHazards, 'Aléas climatiques'],
    [Label.Heist, 'Cambriolage'],
    [Label.Fire, 'Incendie'],
    [Label.BrokenGlass, 'Bris de glace'],
    [Label.Flooding, 'Inondation'],
    [Label.OtherClaims, 'Autre sinistre'],

    // Financial Aid Tags //
    [Label.MaPrimeRenov, 'MaPrimeRenov'],
    [Label.CEE, 'CEE'],
    [Label.EcoPTZ, 'EcoPTZ'],
    [Label.LocalAids, 'Aides locales'],

    // Diagnostic Tags //
    [Label.DPE, 'DPE'],
    [Label.EnergeticDiagnostic, 'Audit énergétique'],
    [Label.ElectricityDiagnostic, 'Electricité'],
    [Label.GazDiagnostic, 'Gaz'],
    [Label.RiskDiagnostic, 'Risques'],
    [Label.SanitationDiagnostic, 'Assainissement'],
    [Label.AsbestosDiagnostic, 'Amiante'],
    [Label.LeadDiagnostic, 'Plomb'],
    [Label.ParasitesDiagnostic, 'Parasites'],

    // Attestation Tags //
    [Label.HabitationCertificate, 'Habitation'],
    [Label.LandlordCertificate, 'Propriétaire'],
    [Label.DamageWorkCertificate, 'Dommage-Ouvrage'],
    [Label.UnpaidRentCertificate, 'Loyer impayé'],
    [Label.DecennialCertificate, 'Décennale'],

    // Maintenance Tags //
    [Label.ChimneySweepingCertificate, 'Ramonage'],
    [Label.MudClearingCertificate, 'Désembouage'],
    [Label.PitEmptyingCertificate, 'Vidange de fosse'],
]);

export const DocumentCategoryIndexToTypesAndQuestions: Map<DocumentCategoryIndex, Label[]> =
    new Map([
        [
            DocumentCategoryIndex.DiagnosticsAndPlans,
            [
                Label.Description,
                Label.Photo,
                Label.GeneralPlan,
                Label.NetworkPlan,
                Label.Diagnostic,
                Label.Other,
            ],
        ],
        [
            DocumentCategoryIndex.Works,
            [
                Label.Estimate,
                Label.Invoice,
                Label.Photo,
                Label.RGECertificate,
                Label.Report,
                Label.Other,
                Label.ShowQuestionWorkPrograms,
                Label.ShowQuestionWorkLots,
            ],
        ],
        [
            DocumentCategoryIndex.EquipmentAndMaintenance,
            [
                Label.Estimate,
                Label.Invoice,
                Label.Notice,
                Label.Description,
                Label.Photo,
                Label.BreakdownAndRepair,
                Label.RGECertificate,
                Label.Other,
            ],
        ],
        [
            DocumentCategoryIndex.UrbanPlanning,
            [
                Label.BuildingPermit,
                Label.Statement,
                Label.DemolitionPermit,
                Label.PlanningPermit,
                Label.Other,
            ],
        ],
        [
            DocumentCategoryIndex.Notary,
            [Label.Lease, Label.ActOfSale, Label.SalesAgreement, Label.Other],
        ],
        [
            DocumentCategoryIndex.Insurance,
            [Label.InsuranceCertificate, Label.Sinister, Label.Report, Label.Photo, Label.Other],
        ],
        [
            DocumentCategoryIndex.Rental,
            [Label.Lease, Label.FixturesState, Label.RentReceipt, Label.Other],
        ],
        [
            DocumentCategoryIndex.CoOwnership,
            [
                Label.ActOfSale,
                Label.CoOwnershipRegulations,
                Label.Report,
                Label.CallForCharges,
                Label.Other,
            ],
        ],
        [DocumentCategoryIndex.Finance, [Label.FinancingPlan, Label.FinancingHelp, Label.Other]],
        [DocumentCategoryIndex.Other, [Label.Other]],
    ]);

export const workLotLabelList: Label[] = [
    Label.FloorTiles,
    Label.Frame,
    Label.Heating,
    Label.Roofing,
    Label.Electricity,
    Label.Insulation,
    Label.Masonry,
    Label.SubjectMastery,
    Label.Carpentry,
    Label.Painting,
    Label.Plastering,
    Label.Plumbing,
];

export const technicalNetworkLabelList: Label[] = [
    Label.ElectricityNetwork,
    Label.GazNetwork,
    Label.DrinkingWaterNetwork,
    Label.WasteWaterNetwork,
    Label.VentilationNetwork,
];

export const sinisterLabelList: Label[] = [
    Label.WaterDamage,
    Label.ClimaticHazards,
    Label.Heist,
    Label.Fire,
    Label.BrokenGlass,
    Label.Flooding,
    Label.OtherClaims,
];

export const financialHelpLabelList: Label[] = [
    Label.MaPrimeRenov,
    Label.CEE,
    Label.EcoPTZ,
    Label.LocalAids,
];

export const diagnosticTypeLabelList: Label[] = [
    Label.DPE,
    Label.EnergeticDiagnostic,
    Label.ElectricityDiagnostic,
    Label.GazDiagnostic,
    Label.RiskDiagnostic,
    Label.SanitationDiagnostic,
    Label.AsbestosDiagnostic,
    Label.LeadDiagnostic,
    Label.ParasitesDiagnostic,
];

export const insuranceCertificateLabelList: Label[] = [
    Label.HabitationCertificate,
    Label.LandlordCertificate,
    Label.DamageWorkCertificate,
    Label.UnpaidRentCertificate,
    Label.DecennialCertificate,
];

export const upkeepCertificateLabelList: Label[] = [
    Label.ChimneySweepingCertificate,
    Label.MudClearingCertificate,
    Label.PitEmptyingCertificate,
];

/*export const equipmentsLabelList: Label[] = [
  Label.HeatingSystem,
  Label.HotWater,
  Label.AirConditioning,
  Label.Cooking,
  Label.HomeAppliances,
  Label.Ventilation,
  Label.Automation,
];*/

export const categoryToQuestionsLabel: Map<DocumentCategoryIndex, Label[]> = new Map([
    [DocumentCategoryIndex.DiagnosticsAndPlans, []],
    [DocumentCategoryIndex.Works, [...workLotLabelList, Label.ShowQuestionWorkLots]],
    [DocumentCategoryIndex.EquipmentAndMaintenance, []],
    [DocumentCategoryIndex.UrbanPlanning, []],
    [DocumentCategoryIndex.Notary, []],
    [DocumentCategoryIndex.Insurance, []],
    [DocumentCategoryIndex.Rental, []],
    [DocumentCategoryIndex.CoOwnership, []],
    [DocumentCategoryIndex.Finance, []],
    [DocumentCategoryIndex.Other, []],
]);

export const typeLabelToQuestionsLabel: Map<Label, Label[]> = new Map([
    [Label.Estimate, []],
    [Label.Invoice, []],
    [Label.Notice, []],
    [Label.Description, []],
    [Label.Photo, []],
    [Label.GeneralPlan, []],
    [Label.NetworkPlan, [...technicalNetworkLabelList, Label.ShowQuestionTechnicalNetwork]],
    [Label.BuildingPermit, []],
    [Label.Statement, []],
    [Label.DemolitionPermit, []],
    [Label.PlanningPermit, []],
    [
        Label.InsuranceCertificate,
        [...insuranceCertificateLabelList, Label.ShowQuestionInsuranceCertificate],
    ],
    [Label.Sinister, [...sinisterLabelList, Label.ShowQuestionSinisterTypology]],
    [Label.Report, []],
    [Label.ActOfSale, []],
    [Label.CoOwnershipRegulations, []],
    [Label.Lease, []],
    [Label.FixturesState, []],
    [Label.RentReceipt, []],
    [Label.Diagnostic, [...diagnosticTypeLabelList, Label.ShowQuestionDiagnosticType]],
    [Label.BreakdownAndRepair, []],
    [Label.SalesAgreement, []],
    [
        Label.MaintenanceCertificate,
        [...upkeepCertificateLabelList, Label.ShowQuestionUpkeepCertificate],
    ],
    [Label.RGECertificate, []],
    [Label.CallForCharges, []],
    [Label.FinancingPlan, []],
    [Label.FinancingHelp, [...financialHelpLabelList, Label.ShowQuestionFinancialHelp]],
    [Label.Other, []],
]);

export const questionLabelsData: Map<Label, { list: Label[] }> = new Map([
    [
        Label.ShowQuestionUpkeepCertificate,
        {
            list: upkeepCertificateLabelList,
        },
    ],
    [
        Label.ShowQuestionInsuranceCertificate,
        {
            list: insuranceCertificateLabelList,
        },
    ],
    [
        Label.ShowQuestionDiagnosticType,
        {
            list: diagnosticTypeLabelList,
        },
    ],
    [
        Label.ShowQuestionFinancialHelp,
        {
            list: financialHelpLabelList,
        },
    ],
    [
        Label.ShowQuestionSinisterTypology,
        {
            list: sinisterLabelList,
        },
    ],
    [
        Label.ShowQuestionTechnicalNetwork,
        {
            list: technicalNetworkLabelList,
        },
    ],
    [
        Label.ShowQuestionWorkLots,
        {
            list: workLotLabelList,
        },
    ],
]);

export const labelsColorData: Map<
    string,
    { labels?: Label[]; categories?: DocumentCategoryIndex[] }
> = new Map([
    [
        'primary',
        {
            labels: [Label.Photo, Label.MaintenanceCertificate],
            categories: [DocumentCategoryIndex.DiagnosticsAndPlans],
        },
    ],
    [
        'red',
        {
            labels: [Label.GeneralPlan, Label.RGECertificate],
            categories: [DocumentCategoryIndex.Works],
        },
    ],
    [
        'light-orange',
        {
            labels: [Label.Notice, Label.Lease],
            categories: [DocumentCategoryIndex.EquipmentAndMaintenance],
        },
    ],
    [
        'yellow',
        {
            labels: [Label.BuildingPermit, Label.FinancingPlan],
            categories: [DocumentCategoryIndex.UrbanPlanning],
        },
    ],
    [
        'green',
        {
            labels: [Label.Statement, Label.FinancingHelp],
            categories: [DocumentCategoryIndex.Notary],
        },
    ],
    [
        'apple-green',
        {
            labels: [Label.DemolitionPermit, Label.Other],
            categories: [DocumentCategoryIndex.Insurance],
        },
    ],
    [
        'cyan',
        {
            labels: [Label.PlanningPermit],
            categories: [DocumentCategoryIndex.Rental],
        },
    ],
    [
        'blue',
        {
            labels: [Label.InsuranceCertificate],
            categories: [DocumentCategoryIndex.CoOwnership],
        },
    ],
    [
        'indigo',
        {
            labels: [Label.Sinister],
            categories: [DocumentCategoryIndex.Finance],
        },
    ],
    [
        'violet',
        {
            labels: [Label.Report],
            categories: [DocumentCategoryIndex.Other],
        },
    ],
    [
        'rose',
        {
            labels: [Label.Estimate, Label.ActOfSale, Label.RentReceipt],
        },
    ],
    [
        'light-red',
        {
            labels: [Label.Diagnostic, Label.Invoice, Label.CoOwnershipRegulations],
        },
    ],
    [
        'orange',
        {
            labels: [Label.NetworkPlan, Label.BreakdownAndRepair, Label.CallForCharges],
        },
    ],
    [
        'blue-gray',
        {
            labels: [Label.Description, Label.FixturesState, Label.SalesAgreement],
        },
    ],
]);

export const documentExtToIcon: Map<string[], string> = new Map([
    [['pdf', 'xps'], 'icon-file-pdf'],
    [['doc', 'docx', 'odt', 'txt'], 'icon-file-doc'],
    [['xlsx', 'xls', 'xlsm', 'xlsb', 'csv', 'ods'], 'icon-file-excel'],
    [['xml', 'html', 'htm'], 'icon-file-markup'],
    [['png', 'svg', 'jpg', 'jpeg', 'webp', 'gif', 'odi', 'heic', 'tif'], 'icon-file-image'],
    [['ppt', 'pps', 'pptx', 'pptm', 'odp'], 'icon-file-ppt'],
]);
