import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    Observable,
    distinctUntilChanged,
    tap,
    switchMap,
    of,
    debounceTime,
    filter,
    map,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LayerService, LayerSize } from '@ed---interne/ng-uui-components';

import { User } from '../../models/user.model';
import { AuthState, Logout } from '../../states/auth.state';
import { UserUpdateComponent } from '../../../main/controllers/admin/users/user-update/user-update.component';

import { SearchService } from 'src/app/core/services/search.service';
import { SidenavService } from 'src/app/main/services/sidenav.service';

// import { EdModalConfirmComponent } from 'src/app/shared/components/modals/ed-modal-confirm/ed-modal-confirm.component';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public projectName = 'ED Web Template';

    public isCollapsed: boolean = true;
    public headerFormGroup: FormGroup;

    public loggedUser$!: Observable<User | null>;
    public loggedUser!: User;

    public searchValue: string = '';
    public isSearching: boolean = false;
    public searchResults: any[] = [];

    public selectOptions = [
        {
            label: 'Régions',
            value: 'region',
        },
        {
            label: 'Département',
            value: 'department',
        },
        {
            label: 'Commune',
            value: 'city',
        },
    ];

    public initials$: Observable<string> | undefined;
    public avatarUrl$: Observable<string> | undefined;

    public isSmallScreen!: boolean;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly store: Store,
        private readonly layerService: LayerService,
        private readonly searchService: SearchService,
        private readonly sidenavService: SidenavService,
    ) {
        inject(BreakpointObserver)
            .observe(sidenavService.breakPointsThreshold)
            .pipe(untilDestroyed(this))
            .subscribe((result) => {
                const matches = result.matches;
                this.isSmallScreen = matches;
            });

        this.headerFormGroup = this.formBuilder.group({
            mySelect: ['region', Validators.required],
            myInput: '',
        });
    }

    ngOnInit() {
        this.loggedUser$ = this.store.select(AuthState.loggedUser);

        this.headerFormGroup
            .get('mySelect')
            ?.valueChanges.subscribe((value) => console.log('select', value));

        this.headerFormGroup
            .get('myInput')
            ?.valueChanges.pipe(
                distinctUntilChanged(),
                debounceTime(300),
                tap(() => {
                    this.isSearching = true;
                }),
                switchMap((term: string | { name: string; code: string }) => {
                    if (typeof term == 'object') {
                        term = term.name;
                    }

                    if (term.length < 3) {
                        return of([]);
                    }
                    return this.searchService.search(term);
                }),
                tap(() => (this.isSearching = false)),
                untilDestroyed(this),
            )
            .subscribe((value) => {
                this.searchResults = value;
            });

        this.loggedUser$.subscribe((data: any) => {
            return (this.loggedUser = data);
        });

        this.avatarUrl$ = this.loggedUser$.pipe(
            filter(Boolean),
            map((user) => {
                if (!user?.avatarPath) {
                    return '';
                }
                return user.avatarPath;
            }),
        );

        this.initials$ = this.loggedUser$.pipe(
            filter(Boolean),
            map((user) => (user.firstname?.charAt(0) || '') + (user.lastname?.charAt(0) || '')),
        );
    }

    public toggleSidenav() {
        this.sidenavService.toggle();
    }

    public openModalUpdateUser(): void {
        const user = this.loggedUser;
        const header = "Mettre à jour l'utilisateur";
        const operation = 'update';
        this.layerService.show(
            UserUpdateComponent,
            { user, header, operation },
            { size: LayerSize.Medium },
        );
    }

    public logOut(): void {
        this.store.dispatch(new Logout());
    }

    public displayFn(option: { name: string; code: string }): string {
        const display = option && option.name ? option.name : '';
        return display;
    }

    // openModal() {
    //     const header = 'Confirmation';
    //     const contentText = `Aimez-vous le header de template_web ?`;
    //     const buttonConfirmText = 'Oui';
    //     const buttonUndoText = 'Non';
    //     // user = { ...user, isActive: false };

    //     this.layerService.show(
    //         EdModalConfirmComponent,
    //         { header, contentText, buttonConfirmText, buttonUndoText },
    //         { size: LayerSize.Large },
    //     );
    // }
}
