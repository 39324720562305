import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ButtonTypes } from '@ed---interne/ng-uui-components';
import { emailValidator } from '../shared/validators/email.validator';
import { Store } from '@ngxs/store';
import { PasswordForgot } from '../core/states/auth.state';
import { Router } from '@angular/router';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
    public ButtonTypes = ButtonTypes;

    public emailSentTo: string | undefined;
    email = '';
    password = '';
    errorMessage = '';

    public forgotPasswordFormGroup: FormGroup = this.formBuilder.group({
        email: ['', [emailValidator()]],
    });

    constructor(
        private readonly formBuilder: FormBuilder,
        private store: Store,
        private router: Router,
    ) {}
    public onSubmit(): void {
        this.passwordInit();
    }

    public async passwordInit() {
        const email = (this.emailSentTo || this.forgotPasswordFormGroup.value.email || '').trim();
        this.emailSentTo = email.toLowerCase();
        this.store.dispatch(
            new PasswordForgot({
                email: email,
            }),
        );
    }

    public send(): void {
        this.passwordInit();
        this.router.navigate(['/forgot-password']);
    }
}
