import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkScrollable, OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { NgxsModule } from '@ngxs/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import * as Sentry from '@sentry/angular';
import {
    AlertModule,
    DropdownMenuModule,
    TextfieldModule,
    LayerModule,
} from '@ed---interne/ng-uui-components';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthState } from './core/states/auth.state';
import { SharedModule } from './shared/shared.module';
import { AdminUsersState } from './main/states/adminUsers.state';
import { initializeAppFactory } from './app.initializer';
import { DocumentsState } from './main/states/documents.state';
import { UserUpdateModule } from './main/controllers/admin/users/user-update/user-update.module';
import { AuthService } from './core/services/auth.service';
import { PaginationState } from './main/states/pagination.state';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        CoreModule,
        SharedModule,
        TextfieldModule,
        LayerModule,
        AlertModule,
        UserUpdateModule,
        BrowserAnimationsModule,
        CdkScrollable,
        DropdownMenuModule,
        OverlayModule,
        MatMenuModule,
        ReactiveFormsModule,
        NgxsModule.forRoot([AuthState, AdminUsersState, DocumentsState, PaginationState]),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthService, Sentry.TraceService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
            useValue: {
                expandedHeight: '28px',
                collapsedHeight: '28px',
            },
        },
        provideEnvironmentNgxMask(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
