import {
    DOCUMENT_CATEGORY_MAP,
    DOCUMENT_LABEL_MAP,
    DocumentCategoryIndex,
    DocumentCategoryIndexToTypesAndQuestions,
    Label,
    labelsColorData,
    typeLabelToQuestionsLabel,
} from './enums/document.enums';

export function getDocumentTypesAndQuestions(
    documentType: DocumentCategoryIndex[],
): { value: number; label: string }[] {
    const labels = new Set<Label>();

    for (const documentCategory of documentType) {
        const potentialDuplicates = DocumentCategoryIndexToTypesAndQuestions.get(documentCategory);

        if (potentialDuplicates) {
            for (const potentialDuplicate of potentialDuplicates) {
                labels.add(potentialDuplicate);
            }
        }
    }

    const labelsArray = Array.from(labels);

    // move "other" label to the end of the array
    if (labelsArray.length > 0 && labelsArray.indexOf(Label.Other) !== -1) {
        labelsArray.push(labelsArray.splice(labelsArray.indexOf(Label.Other), 1)[0]);
    }

    return mapLabels(labelsArray);
}

export function getSecondaryLabelsFromPrimaryLabel(
    label: Label,
): { value: number; label: string }[] {
    const labels = typeLabelToQuestionsLabel.get(label);

    return mapLabels(labels);
}

export function mapLabels(labels: Label[] | undefined): { value: number; label: string }[] {
    return (
        labels?.map((label) => ({
            value: label,
            label: DOCUMENT_LABEL_MAP.get(label) || '',
        })) || []
    );
}

export function getSecondaryLabelsWithoutDuplicates(
    labels: number[],
): { value: number; label: string }[] {
    const secondaryLabels: { value: number; label: string }[] = [];

    for (const label of labels) {
        const potentialDuplicates = getSecondaryLabelsFromPrimaryLabel(label);

        for (const newItem of potentialDuplicates) {
            if (!secondaryLabels.some((item) => item.value === newItem.value)) {
                secondaryLabels.push(newItem);
            }
        }
    }

    return secondaryLabels;
}

export function getLabelIndexByStringValue(map: Map<number, string>, searchValue: string): number {
    for (const [key, value] of map.entries()) {
        if (value === searchValue) return key;
    }

    return -1;
}

export function getLabelsBadgeColor(label: string): string {
    let badgeColor = '';

    labelsColorData.forEach((value, key) => {
        if (
            value.categories?.includes(getLabelIndexByStringValue(DOCUMENT_CATEGORY_MAP, label)) ||
            value.labels?.includes(getLabelIndexByStringValue(DOCUMENT_LABEL_MAP, label))
        ) {
            badgeColor = 'badge-' + key;
        }
    });

    return badgeColor;
}
