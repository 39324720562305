<div [id]="id" 
    class="edInputFile  d-flex justify-content-center text-center"  
    (click)="fileInputDrag.click()"
    (appDropZone)="upload($event)"
    onkeypress=""
    >
    <input  #fileInputDrag type="file" (change)="uploadByEvent($event)" hidden />
    <div class="container">
        <div class="row ">
            <div class="col col-xs-12"> 
                <div class="circle-gray-light">
                    <div class="circle-gray">
                        <i  class=" bi bi-cloud-arrow-up " style="font-size: 2em;"></i>
                    </div>
                </div>
        </div>
        <div class="row r">
            <div class="col col-mxs-12 "> 
                <strong>Cliquez pour télécharger</strong> ou glissez-déposez votre image
        </div>
        <div class="row ">
            <div class="col  col-xs-12"> 
            {{allowedFormat?.join(', ') }}
        </div>
</div>