import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RestService {
    constructor(private http: HttpClient) {}

    download(path: string, parameters?: { [key: string]: any }): Observable<Object> {
        const apiUrl = environment.apiUrl;
        const params: string = this._setParams(parameters || {});
        const url = apiUrl + path + params;
        return this.http.get(url, { responseType: 'blob' });
    }

    get(path: string, parameters?: { [key: string]: any }): Promise<any> {
        return this._send('GET', path, parameters || {});
    }

    put(path: string, parameters?: { [key: string]: any }, data?: any): Promise<any> {
        return this._send('PUT', path, parameters || {}, data);
    }

    delete(path: string, parameters?: { [key: string]: any }): Promise<any> {
        return this._send('DELETE', path, parameters || {});
    }

    post(path: string, parameters?: { [key: string]: any }, data?: any): Promise<any> {
        return this._send('POST', path, parameters || {}, data);
    }
    patch(path: string, parameters?: { [key: string]: any }, data?: any): Promise<any> {
        return this._send('PATCH', path, parameters || {}, data);
    }

    private async _send(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
        path: string,
        parameters: { [key: string]: any },
        data?: any,
    ): Promise<any> {
        const apiUrl = environment.apiUrl;
        const params: string = this._setParams(parameters);
        const url = apiUrl + path + params;
        let promise: Observable<any>;
        if (method === 'GET') {
            promise = this.http.get(url);
        } else if (method === 'DELETE') {
            promise = this.http.delete(url);
        } else if (method === 'PUT') {
            promise = this.http.put(url, data);
        } else if (method === 'PATCH') {
            promise = this.http.patch(url, data);
        } else {
            promise = this.http.post(url, data);
        }

        try {
            const result = await lastValueFrom(promise);
            return result;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    private _setParams(parameters: { [key: string]: any }): string {
        const tmpArray = [];
        for (const [key, value] of Object.entries(parameters)) {
            tmpArray.push(`${key}=${value}`);
        }
        const params = tmpArray.length ? '?' + tmpArray.join('&') : '';
        return params;
    }
}
