import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertModule, ComponentsModule } from '@ed---interne/ng-uui-components';

import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MainComponent } from './controllers/main/main.component';
import { HomeComponent } from './controllers/home/home.component';
import { StatsComponent } from './controllers/stats/stats.component';
import { ContactComponent } from './controllers/contact/contact.component';
import { UsersComponent } from './controllers/admin/users/users.component';
import { FilesComponent } from './controllers/files/files.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { PresentationLayoutComponent } from '../layouts/presentation-layout/presentation-layout.component';
import { SkeletonListComponent } from '../shared/components/skeleton-list/skeleton-list.component';

import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { EdTableModule } from '../shared/components/ed-table/ed-table.module';
import { EdModalConfirmModule } from '../shared/components/modals/ed-modal-confirm/ed-modal-confirm.module';

import { InfiniteScrollDirective } from '../shared/directives/infinite-scroll.directive';

import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    declarations: [
        MainComponent,
        SidenavComponent,
        HomeComponent,
        StatsComponent,
        ContactComponent,
        UsersComponent,
        FilesComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        PresentationLayoutComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        SharedModule,
        ComponentsModule,
        CoreModule,
        SkeletonListComponent,
        EdTableModule,
        EdModalConfirmModule,
        InfiniteScrollDirective,
        ReactiveFormsModule,
        MatMenuModule,
        MatExpansionModule,
        MatSidenavModule,
        MatTableModule,
        MatSortModule,
        AlertModule,
    ],
})
export class MainModule {}
