<div class="page">
    <div class="page-padding">
    <div class="page-header">
        <h1 class="page-title">Fichiers</h1>
        <div class="page-description">Page de gestion des fichiers</div>
    </div>
    <div class="input-file-container mt-4">
        <ed-input-file
            id="edInputFileShowCase"
            [allowedFormat]="['SVG', 'PNG', 'JPG', 'GIF']"
            [isUploading]="isUploading"
            (onSubmit)="onFileUpload($event)"></ed-input-file>
    </div>
</div>
    <div class="page-content">
        <ed-table
            *ngIf="dataSource.data.length"
            (onDropdownMenuClick)="onDropdownMenuClick($event.object, $event.event)"
            [dataSource]="dataSource"
            [displayedColumns]="displayedColumns"
            [areActionsInElements]="true">
        </ed-table>
    </div>
</div>
