import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService, ButtonTypes } from '@ed---interne/ng-uui-components';
import { Store } from '@ngxs/store';
import { PasswordChange } from '../core/states/auth.state';
import { passwordValidator } from '../shared/validators/password.validator';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
    public ButtonTypes = ButtonTypes;
    public changePasswordFormGroup: FormGroup = this.formBuilder.group({
        password: ['', [passwordValidator()]],
    });
    public isValidPassword = false;
    public displayPasswordMessage = false;
    public passwordChanged = false;
    public passwordType = 'password';
    public displayError = false;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private store: Store,
        private readonly alertService: AlertService,
    ) {}

    public onSubmit(): void {
        const password = this.changePasswordFormGroup.value.password || '';
        const token = this.activatedRoute.snapshot.queryParams['token'] || '';

        this.store
            .dispatch(
                new PasswordChange({
                    token: token,
                    password: password,
                }),
            )
            .subscribe({
                next: () => {
                    this.alertService.valid('Mot de passe', 'Changement réussi !');
                    this.passwordChanged = true;
                },
                error: () => {
                    this.alertService.error(
                        'Mot de passe',
                        'Erreur lors du changement de mot de passe',
                    );
                },
            });
    }
}
