import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, notAuthGuard, adminAuthGuard } from './core/guards/auth.guard';
import { LoginModule } from './login/login.module';
import { LoginComponent } from './login/controllers/login/login.component';
import { ContactComponent } from './main/controllers/contact/contact.component';
import { HomeComponent } from './main/controllers/home/home.component';
import { StatsComponent } from './main/controllers/stats/stats.component';
import { FilesComponent } from './main/controllers/files/files.component';
import { MainModule } from './main/main.module';
import { MainComponent } from './main/controllers/main/main.component';
import { UsersComponent } from './main/controllers/admin/users/users.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PresentationLayoutComponent } from './layouts/presentation-layout/presentation-layout.component';

const routes: Routes = [
    {
        path: '',
        component: PresentationLayoutComponent,
        canActivate: [notAuthGuard],
        children: [
            {
                path: '',
                component: LoginComponent,
            },
        ],
    },
    {
        path: 'main',
        component: MainComponent,
        canActivate: [authGuard],
        children: [
            { path: '', component: HomeComponent },
            { path: 'stats', component: StatsComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'files', component: FilesComponent },
        ],
    },
    {
        path: 'admin',
        component: MainComponent,
        canActivate: [adminAuthGuard],
        children: [{ path: 'users', component: UsersComponent }],
    },
    {
        path: 'forgot-password',
        component: PresentationLayoutComponent,
        children: [
            {
                path: '',
                component: ForgotPasswordComponent,
            },
        ],
    },
    {
        path: 'change-password',
        component: PresentationLayoutComponent,
        children: [
            {
                path: '',
                component: ChangePasswordComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), LoginModule, MainModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
