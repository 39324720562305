<div class="page">
    <div class="page-padding">
        <div class="page-header">
            <h1 class="page-title">Page d'accueil</h1>
            <div class="page-description">Bienvenue !</div>
        </div>
    </div>
    <div class="page-content page-padding">
        <p>home works!</p>
        <ed-button (click)="throwTestError()">Test Sentry Error</ed-button>
    </div>
</div>
