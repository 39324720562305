<ng-container *ngIf="!passwordChanged; else passwordChangedBlock">
  <div class="icon-box info mb-3"><i class="icon icon-key-01"></i></div>
  <h1>Nouveau mot de passe</h1>
  <div class="subtitle">Choisissez un nouveau mot de passe unique et gardez le précieusement.</div>

  <form (ngSubmit)="onSubmit()"
        [formGroup]="changePasswordFormGroup">
    <div class="form-group">
      <ed-textfield id="password"
                    [type]="'password'"
                    [formControlName]="'password'"
                    placeholder="Choisissez votre mot de passe"
                    ></ed-textfield>
    </div>
    <div class="message"
        [ngClass]="{
          'text-muted': true,
          'font-weight-bold': true
        }"
        *ngIf="!changePasswordFormGroup.get('password')?.dirty || changePasswordFormGroup.get('password')?.value=='' || changePasswordFormGroup.get('password')?.valid">
    Au moins 8 caractères, dont 1 chiffre, 1 majuscule et un caractère spécial ? ! / , ;
    </div>
    <div class="button-group">
      <ed-button [type]="ButtonTypes.Submit"
                 [disabled]="changePasswordFormGroup.invalid || changePasswordFormGroup.value.password===''">Réinitialiser mon mot de passe</ed-button>
    </div>

  </form>

    <a routerLink="/"><i class="icon icon-arrow-left px-1"></i>Se connecter</a>

</ng-container>

<ng-template #passwordChangedBlock>
  <div class="icon-box valid mb-3"><i class="icon icon-check-circle"></i></div>
  <h1>Mot de passe enregistré</h1>
  <div class="subtitle">Votre nouveau mot de passe a été enregistré.<br>Vous pouvez dès à présent vous connecter.</div>
  <ed-button class="full-width" routerLink="/">Se connecter</ed-button>
</ng-template>
