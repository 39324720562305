import { Component, Input } from '@angular/core';
import { getLabelsBadgeColor } from '../../labels.helpers';

@Component({
    selector: 'ed-document-label',
    templateUrl: './document-label.component.html',
    styleUrls: ['./document-label.component.scss'],
})
export class DocumentLabelComponent {
    public getLabelsBadgeColor = getLabelsBadgeColor;

    @Input()
    public label: string | undefined;

    @Input()
    public smallFont = false;
}
