import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { checkPassword } from '../form.helpers';

export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isValidPassword = checkPassword(control.value);
        const valid = isValidPassword || !control.value;

        return !valid ? { password: 'error' } : null;
    };
}
