import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'ed-skeleton-list',
    templateUrl: './skeleton-list.component.html',
    styleUrls: ['./skeleton-list.component.scss'],
})
export class SkeletonListComponent {
    @Input()
    public numberOfRows = 5;

    @Input()
    public numberOfBlocs = 6;
}
