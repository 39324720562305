import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDrawer } from '@angular/material/sidenav';

import { AuthService } from '../../../core/services/auth.service';
import { SidenavService } from 'src/app/main/services/sidenav.service';

@UntilDestroy()
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    @ViewChild('drawer') drawer: MatDrawer | undefined;

    public displayToggleClass!: boolean;
    public displaySidenavClass!: boolean;

    constructor(
        private readonly authService: AuthService,
        private readonly sidenavService: SidenavService,
    ) {
        inject(BreakpointObserver)
            .observe(sidenavService.breakPointsThreshold)
            .pipe(untilDestroyed(this))
            .subscribe((result) => {
                const matches = result.matches;
                this.displayToggleClass = matches;
                this.displaySidenavClass = !matches;
            });
    }

    ngOnInit(): void {
        this.sidenavService.toggleSidenavObs$.subscribe((toggle) => {
            this.drawer?.toggle();
        });
    }

    public toggleSidenav(): void {
        this.drawer?.toggle();
    }
}
