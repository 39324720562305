import { Component, inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    map,
    Observable,
    Subject,
    Subscription,
} from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { AuthState } from 'src/app/core/states/auth.state';
import {
    AdminUsersState,
    DeleteUser,
    GetPaginatedUsers,
} from 'src/app/main/states/adminUsers.state';
import { EdModalConfirmComponent } from 'src/app/shared/components/modals/ed-modal-confirm/ed-modal-confirm.component';
import {
    AlertService,
    DropdownMenuItem,
    LayerService,
    LayerSize,
    PopupService,
    PopupTypes,
} from '@ed---interne/ng-uui-components';
import { UserUpdateComponent } from './user-update/user-update.component';
import {
    DisplayedColumns,
    DisplayType,
} from 'src/app/shared/components/ed-table/ed-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Pagination } from 'src/app/shared/all.types';
import { PaginationState } from 'src/app/main/states/pagination.state';
import { AdminUsersService } from 'src/app/main/services/adminUsers.service';
import { LayerPopupOptions } from 'src/app/shared/all.constants';

interface UserTableElement {
    id: string;
    firstname: string;
    lastname: string;
    mail: string;
    createdAt: Date | undefined;
    lastConnexionAt: Date | undefined;
    isAdmin: boolean | undefined;
    isActive: boolean | undefined;
    actions: DropdownMenuItem[];
}
enum UserMenuEvent {
    Desactive = 'Desactive',
    Active = 'Active',
    Modify = 'Modify',
    Delete = 'Delete',
}
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
    @Select(AdminUsersState.users)
    public users$!: Observable<User[]>;
    @Select(AdminUsersState.hasMoreUsers)
    public hasMoreUsers$!: Observable<boolean>;
    public hasUsers$!: Observable<boolean>;
    @Select(PaginationState.users)
    public paginationInfo$!: Observable<Pagination>;

    loggedUser$: Observable<User | null> = inject(Store).select(AuthState.loggedUser);

    private loggedUser: User | undefined;
    public isLoadingUsers = false;

    private _subscriptions: Subscription[] = [];
    public filterChanged = new Subject<string>();

    public dataSource: MatTableDataSource<UserTableElement> =
        new MatTableDataSource<UserTableElement>();

    public displayedColumns: DisplayedColumns[] = [
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Identifiant',
            objectKey: 'id',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Prénom',
            objectKey: 'firstname',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Nom',
            objectKey: 'lastname',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Email',
            objectKey: 'mail',
        },
        {
            displayType: DisplayType.DATE,
            objectDisplayName: "Date d'inscription",
            objectKey: 'createdAt',
        },
        {
            displayType: DisplayType.DATE,
            objectDisplayName: 'Dernière visite',
            objectKey: 'lastConnexionAt',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Administrateur',
            objectKey: 'isAdmin',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Compte actif',
            objectKey: 'isActive',
        },
    ];

    public paginationInfo: Pagination = {
        orderBy: { field: 'createdAt', order: -1 },
        page: 1,
        search: '',
    };

    constructor(
        private store: Store,
        private readonly layerService: LayerService,
        private adminUsersService: AdminUsersService,
        private readonly popupService: PopupService,
        private readonly alertService: AlertService,
    ) {}

    ngOnInit() {
        const paginationInfo = this.store.selectSnapshot(PaginationState.users);
        const payload = paginationInfo ? { ...paginationInfo, page: 1 } : undefined;
        this.store.dispatch(new GetPaginatedUsers(payload, true));

        this.hasUsers$ = this.users$!.pipe(map((users) => users?.length > 0));
        // // Souscription pour obtenir les informations de l'utilisateur connecté
        this.loggedUser$.subscribe((data: any) => {
            this.loggedUser = data;
        });

        this._subscriptions.push(
            combineLatest([this.users$]).subscribe(([users]) => {
                users = users || [];
                this.isLoadingUsers = false;

                this.dataSource = new MatTableDataSource<UserTableElement>(
                    users!.map((user) => {
                        const isConnected = this.loggedUser?.id === user.id;
                        return {
                            id: user.id,
                            firstname: user.firstname,
                            lastname: user.lastname,
                            mail: user.mail,
                            createdAt: user.createdAt,
                            lastConnexionAt: user.lastConnexionAt,
                            isAdmin: user.isAdmin,
                            isActive: user.isActive,
                            actions: this._getActions(user.isActive, isConnected),
                        };
                    }),
                );
                // Hack to forbid mat table to re-sort wrongly our table
                this.dataSource.sortData = (data: UserTableElement[]) => {
                    return data;
                };
            }),
        );

        this._subscriptions.push(
            this.filterChanged.pipe(debounceTime(800), distinctUntilChanged()).subscribe({
                next: (result) => {
                    this.paginationInfo.search = result;
                    this.paginationInfo.page = 1;
                    this.store.dispatch(new GetPaginatedUsers(this.paginationInfo, true));
                },
            }),
        );
    }

    public deleteUser(user: User): void {
        this.popupService
            .openPopup({
                title: "Suppression d'utilisateur",
                htmlContent: `Voulez-vous supprimer définitivement l'utilisateur <b>${user.mail}</b> ?`,
                confirmButtonText: "Supprimer l'utilisateur",
                type: PopupTypes.Delete,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.store.dispatch(new DeleteUser({ userId: user?.id })).subscribe({
                        next: () => {
                            this.alertService.valid(
                                'Suppression',
                                "L'utilisateur a bien été supprimé",
                            );
                        },
                        error: (err) => {
                            this.alertService.error(
                                'Suppression',
                                "une erreur est survenue : Impossible de supprimer l'utilisateur",
                            );
                        },
                    });
                }
            });
    }

    public deActivateUser(user: User): void {
        var header = 'Confirmation';
        var contentText = `Voulez-vous désactiver l'utilisateur ${user.mail}?`;
        var buttonConfirmText = 'Désactiver';
        var buttonUndoText = 'Annuler';

        const userToUpdate: User = {
            id: user.id,
            mail: user.mail,
            firstname: user.firstname,
            lastname: user.lastname,
            password: user.password,
            isAdmin: user.isAdmin,
            isActive: false,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            lastConnexionAt: user.lastConnexionAt,
        };
        user = userToUpdate;
        this.layerService.show(
            EdModalConfirmComponent,
            { user, header, contentText, buttonConfirmText, buttonUndoText },
            { size: LayerSize.Medium },
        );
    }

    public activateUser(user: User): void {
        var header = 'Confirmation';
        var contentText = `Voulez-vous activer l'utilisateur ${user.mail}?`;
        var buttonConfirmText = 'Activer';
        var buttonUndoText = 'Annuler';
        const userToUpdate: User = {
            id: user.id,
            mail: user.mail,
            firstname: user.firstname,
            lastname: user.lastname,
            password: user.password,
            isAdmin: user.isAdmin,
            isActive: true,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            lastConnexionAt: user.lastConnexionAt,
        };
        user = userToUpdate;
        this.layerService.show(
            EdModalConfirmComponent,
            { user, header, contentText, buttonConfirmText, buttonUndoText },
            { size: LayerSize.Medium },
        );
    }

    public openModalUpdateUser(user: User): void {
        var operation = 'update';
        var header = "Mettre à jour l'utilisateur";
        this.layerService.show(
            UserUpdateComponent,
            { user, header, operation },
            { size: LayerSize.Medium },
        );
    }

    public openModalCreateUser(): void {
        var operation = 'create';
        var header = 'Créer un nouvel utilisateur';
        var user: User = {
            id: '',
            mail: '',
            firstname: '',
            lastname: '',
            isAdmin: false,
        };
        this.layerService.show(
            UserUpdateComponent,
            { user, header, operation },
            { size: LayerSize.Medium, withClosePopup: LayerPopupOptions.Creating },
        );
    }

    private _getActions(isActive: boolean | undefined, isConnected: boolean): DropdownMenuItem[] {
        const actions: DropdownMenuItem[] = [];
        if (!isConnected) {
            if (isActive) {
                actions.push({
                    icon: 'icon-eye',
                    text: 'Désactiver',
                    outputEventString: UserMenuEvent.Desactive,
                });
            } else {
                actions.push({
                    icon: 'icon-eye',
                    text: 'Activer',
                    outputEventString: UserMenuEvent.Active,
                });
            }
        }

        actions.push({
            icon: 'icon-edit-02',
            text: 'Modifier',
            outputEventString: UserMenuEvent.Modify,
        });

        if (!isConnected) {
            actions.push({
                icon: 'icon-trash-01',
                text: 'Supprimer',
                outputEventString: UserMenuEvent.Delete,
            });
        }

        return actions;
    }

    public onDropdownMenuClick(user: User, event: string): void {
        switch (event) {
            case UserMenuEvent.Desactive:
                this.deActivateUser(user);
                break;
            case UserMenuEvent.Active:
                this.activateUser(user);
                break;
            case UserMenuEvent.Modify:
                this.openModalUpdateUser(user);
                break;
            default:
                this.deleteUser(user);
                break;
        }
    }

    public async goToDetail(idUser: number): Promise<void> {
        const user = await this.adminUsersService.getUserById(idUser);
        this.openModalUpdateUser(user);
    }

    public onSortChange(sort: MatSort): void {
        const orderBy = sort?.active
            ? {
                  field: sort.active,
                  order: sort.direction === 'asc' ? 1 : sort.direction === 'desc' ? -1 : 0,
              }
            : undefined;
        this.paginationInfo = {
            orderBy,
            page: 1,
            search: this.paginationInfo.search,
        };
        this.store.dispatch(new GetPaginatedUsers(this.paginationInfo, true));
    }

    public displayMoreUsers(): void {
        this.isLoadingUsers = true;
        this.paginationInfo.page++;
        this.store.dispatch(new GetPaginatedUsers(this.paginationInfo));
    }

    public applyFilter(event: string): void {
        this.paginationInfo.search = event;
        this.filterChanged.next(event);
    }
}
