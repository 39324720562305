import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, TextfieldModule } from '@ed---interne/ng-uui-components';
import { UserUpdateComponent } from './user-update.component';

@NgModule({
    declarations: [UserUpdateComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule, TextfieldModule],
    exports: [UserUpdateComponent],
})
export class UserUpdateModule {}
