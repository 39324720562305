import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, delay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private searchUrl = 'https://api.example.com/search';
    private regions = [
        { name: 'Auvergne-Rhône-Alpes', code: '84' },
        { name: 'Bourgogne-Franche-Comté', code: '27' },
        { name: 'Bretagne', code: '53' },
        { name: 'Centre-Val de Loire', code: '24' },
        { name: 'Corse', code: '94' },
        { name: 'Grand Est', code: '44' },
        { name: 'Hauts-de-France', code: '32' },
        { name: 'Île-de-France', code: '11' },
        { name: 'Normandie', code: '28' },
        { name: 'Nouvelle-Aquitaine', code: '75' },
        { name: 'Occitanie', code: '76' },
        { name: 'Pays de la Loire', code: '52' },
        { name: "Provence-Alpes-Côte d'Azur", code: '93' },
        { name: 'Guadeloupe', code: '01' },
        { name: 'Martinique', code: '02' },
        { name: 'Guyane', code: '03' },
        { name: 'La Réunion', code: '04' },
        { name: 'Mayotte', code: '06' },
    ];

    constructor() {}

    // search(term: string): Observable<any[]> {
    //     return this.http.get<any[]>(`${this.searchUrl}?q=${term}`).pipe(
    //         map((response) => {
    //             return response;
    //         }),
    //     );
    // }

    search(term: string): Observable<{ name: string; code: string }[]> {
        const region = this.regions.filter((region) =>
            region.name.toLowerCase().includes(term.toLowerCase()),
        );

        // Simulating a network delay
        return of(region).pipe(delay(500));
    }
}
