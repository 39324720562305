import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentLabelComponent } from './document-label.component';

@NgModule({
    declarations: [DocumentLabelComponent],
    exports: [DocumentLabelComponent],
    imports: [CommonModule],
})
export class DocumentLabelModule {}
