import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';

import { AuthService } from './services/auth.service';
import { JwtInterceptor } from './services/jwt.interceptor';
import { ToastService } from './services/toast.service';
import { ToastComponent } from './components/toast/toast.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { SharedModule } from '../shared/shared.module';
import {
    AlertModule,
    DropdownMenuModule,
    LayerModule,
    PopupModule,
    ButtonModule,
    CheckboxModule,
    TextareaModule,
    NumberfieldModule,
    SpinnerModule,
    StepperModule,
    TextfieldModule,
    SelectModule,
} from '@ed---interne/ng-uui-components';

import { HeaderComponent } from './components/header/header.component';

@NgModule({
    declarations: [HeaderComponent, ToastComponent, ToasterComponent],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgxsModule,
        AlertModule,
        DropdownMenuModule,
        LayerModule,
        PopupModule,
        ButtonModule,
        CheckboxModule,
        TextareaModule,
        NumberfieldModule,
        SpinnerModule,
        StepperModule,
        TextfieldModule,
        SelectModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatMenuModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        AuthService,
        ToastService,
    ],
    exports: [HeaderComponent, ToastComponent, ToasterComponent],
})
export class CoreModule {}
